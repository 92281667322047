.coupon-input-box {
  display: flex;
  align-items: center;
  margin-top: 10px;

  button {
    background: var(--button-color);
    color: white;
    font-size: 12px;
    height: 34px;
    border: none;
    width: 33%;
    border-radius: 0px 5px 5px 0px;
  }

  input {
    border: 1px solid #e4e4e4;
    height: 34px;
    font-size: 11px;
    border-radius: 5px 0px 0px 5px;
  }
}
html[lang="ar"] .coupon-input-box {
  input {
    border-radius: 0px 5px 5px 0px;
  }
  button {
    border-radius: 5px 0px 0px 5px;
  }
}
.hide-border-top {
  border-top: none !important;
}
.promo-text {
  color: $cart-title;
}
.promo-code {
  transition: 0.5s;
  border-top: 0.5px solid #dfdddf;
  padding-top: 10px;
  margin-top: 5px;
}
