$primary-color: var(--button-color);
$bg-light: #f8f9fa;
$border-color: #ddd;
$text-dark: #333;
.checkout-page {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  .checkout-page__content {
    flex: 2;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (min-width: 1024px) {
    //   grid-template-columns: 2fr 1fr;
    }
  }

  // Cart Section
  .cart-container {
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .cart-container__title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .cart-item-d {
    // display: flex;
    // flex-direction: column;
    background: white;
    padding: 16px;
    border: 0.5px solid #c3c5ca;
    border-radius: 4px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;

    @media (min-width: 640px) {
      flex-direction: row;
      //   align-items: center;
    }
    .cart-item__name {
      font-size: 1.6rem;
      font-weight: bold;
    }
    .cart-item__image {
      width: 145px;
      height: 128px;
      border-radius: 4px;
    }

    .item-info {
      flex: 1;
      margin-left: 16px;
      margin-right: 16px;
      h2 {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .price {
        color: gray;
      }

      .quantity {
        display: flex;
        align-items: center;
        margin-top: 8px;

        button {
          padding: 4px 8px;
          border: 1px solid $border-color;
          border-radius: 4px;
          background: white;
          cursor: pointer;
        }

        span {
          margin: 0 8px;
        }
      }

      .total {
        margin-top: 8px;
        font-weight: bold;
      }
    }

    .cart-item__remove {
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
    }
  }

  // Order Summary
  .order-summary {
    background: white;
    padding: 16px;
    height: 100%;
    border-radius: 4px;
    flex: 1;
    max-width: 350px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
      color: #000000;
      font-size: 1.6rem;
      font-weight: 600;
    }

    .summary-item {
      margin: 8px 0;
    }

    .coupon {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 8px 0;

      input {
        padding: 8px;
        border: 1px solid $border-color;
        border-radius: 4px;
      }

      button {
        background: $primary-color;
        color: white;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .submit-btn {
      background: $primary-color;
      color: white;
      padding: 10px;
      width: 100%;
      margin-top: 16px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  // Receiver Info
  .receiver-info {
    background: white;
    padding: 16px;
    border-radius: 4px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .input-group {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      input,
      textarea {
        padding: 8px;
        border: 1px solid $border-color;
        border-radius: 4px;
        width: 100%;
      }

      textarea {
        grid-column: span 2;
      }
    }

    .char-count {
      text-align: right;
      font-size: 0.875rem;
      color: gray;
    }
  }
}
