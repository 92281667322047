.maps_show {
  position: relative;
  /* height: 564px; */
  height: 60vh;
  /* height: 76vh; */
  width: 100%;
}

.google-map-box {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  // height: 300px;
}
.location-tool-tip.active {
  display: inline-block;
}
.disabled_order {
  background: #eeee;
}
.location-tool-tip {
  border: 1px solid;
  border-radius: 13px;
  color: #fff;
  display: none;
  padding: 8px 0;
  position: relative;
  top: -59px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}
.location-tool-tip .tooltiptext {
  visibility: visible;
  width: 275px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 7px 0;
  border-radius: 12px;
  left: 50%;
  transform: translateX(-50%);
  top: -4px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 48%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.current-location-button {
  width: 100%;
  position: fixed;
  bottom: 36vh;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-17%);
  bottom: 17%;
  // @media #{$xs-layout} {
  //   bottom: 19vh;
  // }
}
.confirm_current_location {
  background: $theme-color;
  border: solid $theme-color;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  margin: 20px;
  width: 90%;
  padding: 12px 6px;
  // width: 86%;
}

.current-location-button button {
  color: var(--background-color-store) !important;
}
.current-location-button button {
  height: auto;
  background-color: #fff;
  color: orange;
  border-color: orange;
  border: 1px solid;
  font-weight: bold;
  text-align: center;
  width: 230px;
  padding: 8px 15px;
  box-shadow: -3px -1px 31px 0px rgb(0 0 0 / 22%);
  -webkit-box-shadow: 0px -1px 8px 0px rgb(0 0 0 / 53%);
  border-radius: 6px;
  font-size: 12px;
  text-wrap: nowrap;
}
.current-location-button button svg {
  width: 1.8rem !important;
  height: 1.8rem !important;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  // top: 4px;
  position: relative;
}
.after-dragging {
  position: absolute;
  top: 11px;
  background: rgb(235, 4, 4);
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
  z-index: 2;
  width: 10px;
  height: 10px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.before-dragging {
  /* background: rgb(235, 4, 4); */
  background: rgba(6, 5, 5, 0.16);
  width: 110px;
  height: 110px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.2s;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
  z-index: 16;
}

.overlay:target + body {
  overflow: hidden;
}

.pop_current_location_maps_show {
  width: 100%;
  height: 100%;
  background: #fff;
}

.delivery_search_input {
  border: 1px solid var(--button-color);
  padding: 0px 12px 0px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.has-search .form-control-feedback {
  top: 9px;
  left: 0;
  color: $theme-color;
  font-size: 18px;
  padding: 0px 18px;
}
.delivery_search > .glyphicon-plus {
  float: left;
  margin: 0px;
  background: transparent;
  padding: 0px;
  margin-right: -12px;
  font-size: 20px;
}
.google-map-box {
  overflow-y: scroll;
  height: 300px;
}
.delivery_current_loaction_arow {
  float: right;
  padding: 6px;
}
.delivery_searched_loaction {
  padding: 0px 8px;
  float: left;
}
.searched_loaction_text_head {
  color: #000000;
  font-size: 14px;
  margin-bottom: 5px;
  /* font-family: var(--font-medium); */

  /* width: 90%; */
}

.searched_loaction_text_foot {
  color: #828282;
  font-size: 10px;
}
.delivery_current_loaction {
  padding-left: 8px;
  padding-right: 8px;
}
.current_loaction_text_head {
  color: #ffa600;
  color: $theme-color;
}
.unavailable-address {
  color: #d4d4d4 !important;
  cursor: not-allowed;
}
