.progress-indicator {
  display: flex;
  justify-content: space-between;
  //   align-items: center;
  padding: 20px 0;
  direction: rtl;
  /* Ensures the entire progress bar respects RTL */
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.circle-line-wrapper {
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  z-index: 1;
}

.circle.completed {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.circle.active {
  border-color: #007bff;
  background-color: white;
  color: #007bff;
}

.line {
  flex-grow: 1;
  height: 0;
  /* Set height to 0 since we're using border */
  border-top: 1px dashed lightgrey;
  /* Add dashed border */
  margin: 10px -10px;
}

.step p {
  margin-top: 5px;
  font-size: 12px;
  color: black;
  text-align: center;
}

.fa-check {
  color: white;
}
