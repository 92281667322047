.outer-div {
  display: flex;
    flex-direction: column;
    background: white;
    padding: 15px;
    border-radius: 10px;
  }
  
  .top-level-key {
    overflow: scroll;
    // border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .inner-div {
    // border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
//   img {
//     max-width: 100%;
//     height: auto;
//     cursor: pointer;
//   }
  
//   h4 {
//     margin: 0;
//     margin-bottom: 10px;
//   }
  
//   ul {
//     list-style-type: none;
//     padding: 0;
//   }
  
//   li {
//     margin-bottom: 5px;
//   }