.timeModalBody {
  height: 500px;
  overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
      }
}
.delivery_head {
  font-size: 20px;
  font-weight: 500;
}

.calenderModalBody {
  height: 600px;
  overflow: scroll;
}

.delivery_time_text {
  font-size: 14px;
  color: #828282;
  padding: 2px 0px 10px 0px;
}
.col_50_time_date {
  width: 48%;
  float: left;
  margin: 0px 1%;
  /* height: 350px; */
  /* height: 500px; */
  /* height: 60vh; */
  max-height: 66vh;

  overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
      }
}
.select_date_option {
  width: 96%;
  margin: 4px 2%;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  background: #f7f9fa;
  color: #ababab;
  padding: 11px;
  text-transform: capitalize;
}

.select_date_option > a > span {
  font-family: auto;
}
.selcted_opt {
  background: $theme-color;
  color: #fff;
}
.calendar-container {
  width: 98%;
  // height: 500px;
  // max-width: 90%; /* Optional: Set a maximum width if needed */
}
.text-color-store {
  color: $theme-color !important;
}
