.button-common {
  font-weight: 500;
  line-height: 1;
  z-index: 9;
  display: block;
  width: 100%;
  padding: 14px 20px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: none;
  background-color: $theme-button-color;
}
