.modal-user-address {
  height: 300px;
  border-radius: 15px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: #f7f9fa;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.delivery_search {
  background-color: white;
  border-radius: 5px;
}
.form_group {
  padding: 15px 10px;
}
.receiver-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s;
  padding: 10px 20px 12px 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  // margin-left: 25px;
  border-bottom: 8px solid #eeee;
  // margin-right: 25px;
}
.address-modal-addnew {
  position: absolute;
  bottom: 0;
  background: #ffffff;
  display: flex;
  border-radius: 15px;
  padding: 10px 10px 10px 25px;
  width: 100%;
  left: 0;
  @media #{$xxs-layout} {
    display: block !important;
  }
}

.order_rows_border {
  // align-items: center;
  background: #fdfdfd;
  border: 0.5px solid #9b9b9b;
  border-radius: 6px;
  // display: flex;
  justify-content: space-between;
  padding: 15px;
}
.address-action-buttons {
  width: 100%;
  border-top: 1px solid #eeee;
  padding-top: 20px;
  input {
    display: inline-block;
    float: left;

    width: 20px;
    height: 20px;

    border: 1px solid #9fa0a2;
  }
}
.lbl-action-address {
  padding-left: 8px;
  padding-right: 8px;
}
.border-left-right {
  border-left: 1px solid;
  border-right: 1px solid;
}
.button-add-new {
  font-weight: 500;
  line-height: 1;
  z-index: 9;
  display: block;
  width: 100%;
  padding: 15px 15px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: none;
  background-color: $theme-color;
}
.button-add-new-withoutbg {
  color: var(--button-color);
  border: 1px solid $theme-color;

  background-color: #fff !important;
}
.btn-disable {
  background-color: #d4d4d4 !important;
}
.useAddressButton {
  background-color: $theme-color;
  color: #ffffff;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
}
/*add new address*/
.add-new-address {
  height: 500px;
  overflow: scroll;
}
.cart_foot_button_sm_rev {
  width: 30%;
  margin: 4px 1.27%;
  border: 1px solid #828282;
  border-radius: 5px;
  background: #fff;
  color: #828282;
  padding: 14px 6px;
}
.cart_bt_select {
  border: 1px solid $theme-color;
  /* color: var(--background-color-store); */
  background: $theme-color;
  color: #fff;
}
/*add new address close*/
