html[lang="ar"] {
  --toastify-font-family: var(--font-ar-light) !important;
}
html[lang="ar"] .offcanvas-mobile-search-area button {
  left: 20px !important;
  right: auto !important;
}

html[lang="ar"] .header-right-wrap .shopping-cart-content {
  left: 0 !important;
  right: auto !important;
}
html[lang="ar"] .body-toast-mobile {
  direction: rtl;
}
html[lang="ar"] .arabic-invert {
  transform: rotate(270deg);
}
html[lang="ar"] span.count-style {
  right: unset !important;
  left: -14px;
}
html[lang="ar"] .fa-phone {
  padding: 0px 2px 0px 2px !important;
  transform: rotate(270deg) !important;
}

html[lang="ar"] .product-img-badges {
  left: 10px;
  right: auto;
}
html[lang="ar"] .feature-tag-container {
  right: 0px;
  left: unset;
}
html[lang="ar"] .feature-new-badge {
  right: -18px;
  left: unset;
}

html[lang="ar"] .feature-new-badge span {
  right: -6px;
}
html[lang="ar"] .feature-tag{
border-radius: 4px 0px 0px 4px;
}
html[lang="ar"] .header-right-wrap,
html[lang="ar"] .same-style.cart-wrap,
html[lang="ar"] .shopping-cart-content.cart-visible,
html[lang="ar"]
  .header-right-wrap
  .same-style.cart-wrap
  .shopping-cart-content.active,
html[lang="ar"]
  .header-right-wrap
  .same-style.header-wishlist
  .shopping-cart-content.cart-visible,
html[lang="ar"]
  .header-right-wrap
  .same-style.header-wishlist
  .shopping-cart-content.active,
html[lang="ar"]
  .header-right-wrap
  .same-style.header-compare
  .shopping-cart-content.cart-visible,
html[lang="ar"]
  .header-right-wrap
  .same-style.header-compare
  .shopping-cart-content.active {
  left: 0 !important;
  right: auto !important;
  right: initial !important;
}

html[lang="ar"] .single-banner-2 .banner-content-2 h3 {
  font-family: var(--font-ar-light) !important;
}
html[lang="ar"] .single-banner-2 .banner-content-2 h4 {
  font-family: var(--font-ar-light) !important;
}
html[lang="ar"] .breadcrumb-item + .breadcrumb-item::before {
  float: right !important;
}

html[lang="ar"] .main-menu nav ul li ul.mega-menu > li {
  float: right;
}

html[lang="ar"] .header-right-wrap .same-style .account-dropdown {
  text-align: right !important;
  width: 170px;
}

html[lang="ar"] .main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a {
  // float: right !important;
}

html[lang="ar"] .main-menu nav ul li ul.mega-menu > li ul li a {
  text-align: right !important;
}

html[lang="ar"] .slider-content-7 {
  text-align: right !important;
  margin-left: 10px;
  margin-right: 15px;
  @media #{$xs-layout} {
  }
}

html[lang="ar"] .dir-lang {
  direction: rtl;
}
.dir-lang {
  direction: ltr;
}

html[lang="ar"] .scroll-top {
  right: unset;
  left: 20px;
}
html[lang="ar"] .whats-contact-wrapper {
  right: unset;
  left: 0px;
}

// html[lang="ar"] .header-right-wrap .same-style.header-search .search-content.active {
//   right: unset;
//   left: 0;
// }

html[lang="ar"] .header-right-wrap .same-style.header-search .search-content {
  right: unset;
  left: 0;
}
html[lang="ar"] .breadcrumb-item + .breadcrumb-item::before {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

html[lang="ar"] .product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a,
.product-wrap-2 .product-content-2 .title-price-wrap-2 .h3 a {
  font-family: var(--font-ar-light);
}

html[lang="ar"] .single-review .review-top-wrap .review-left .review-name {
  margin-right: 0px;
  margin-left: 15px;
}

html[lang="ar"] .arabic-bold {
  font-family: var(--font-ar-medium) !important;
}

html[lang="ar"] .icon_sm {
  float: right !important;
}
html[lang="ar"] .address-actions {
  margin-left: unset;
  margin-right: auto;
}

html[lang="ar"] .bold {
  font-family: var(--font-ar-medium) !important;
}
