.cart_tick {
  -webkit-text-stroke: 32px #4bae4f;
  border-radius: 200px;
  color: #4bae4f;
  display: flex;
  font-size: 200px;
  margin: 7px auto;
  outline: 46px solid #fff;
  outline-offset: -30px;
  width: 200px;
}
.order-number {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}
.cart_thanks_dis {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}
.cart_confirmation_alert {
  text-align: center;
  text-transform: uppercase;
  background: #fffbf3;
  padding: 14px 0px;
  color: $theme-color;
  border-radius: 5px;
}
.cart_foot_button_rev {
  background: #fff;
  border: 1px solid #ffa600;
  border: 1px solid $theme-color;
  border-radius: 5px;
  color: #ffa600;
  color: $theme-color;
  margin: 7px 7%;
  padding: 12px 6px;
  text-transform: uppercase;
  width: 86%;
}
.light_grey_text {
  color: #9f9f9f;
  font-weight: 300 !important;
  // padding-right: 5px;
}
.cart_foot {
  // width: 50%;
  align-items: center;
  @media #{$xxs-layout} {
    width: 100%;
  }
}
.share-button-thankyou {
  width: 50%;
  @media #{$xxs-layout} {
    width: 100%;
  }
}
.trackorder-button {
  width: 44%;
  @media #{$xxs-layout} {
    width: 100%;
  }
}
