.checkout-container {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;

  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.address-wrapper {
  margin-top: 15px;
}
.pencil-svg-bg {
  border: 1px solid #dfdfdf;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout {
  font-size: 14px !important;
  //   padding: 0;
  border-bottom: 1.5px dotted #dfdfdf;

  .product-name {
    color: #2e2e2e !important;
    width: 65%;
    text-align: start;
  }
}
.checkout-name {
  font-weight: bold;
  font-size: 20px;
}
.checkout-header {
  text-align: center;
}

.header h2 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.amount-section {
  color: #2e2e2e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-amount {
  font-weight: bold;
  color: #000;
  font-size: 14px;
}

.item-label {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}

/* Coupon Section */
.coupon-section {
  margin-bottom: 20px;
  margin-top: -20px;
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 0;
  opacity: 0;
}

.coupon-text {
  display: flex;
  font-size: 10px;
  color: red;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 10px;
}

.coupon-input-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.coupon-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.coupon-submit-btn {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
}

/* Shipping Address Section */
.shipping-address-section {
  margin-bottom: 20px;
}

.section-title {
  display: flex;
  align-items: center;
}

.section-number {
  display: flex;
  border-radius: 50%;
  /* padding: 10px; */
  width: 47px;
  justify-content: center;
  font-size: 20px;
  line-height: 29px;
  align-items: center;
  height: 47px;
  border: 1px solid #929394;
  color: #929394;
  text-align: center;
  &.confirmed {
    padding: 0;
    background-color: white;
    border: 1px solid var(--button-color);
  }
  &.active {
    background-color: var(--button-color);
    color: white;
    border: none;
  }
}
.current-section-title {
  font-size: 18px;
  font-weight: bold;
}
.current-address {
  font-size: 14px !important;
  line-height: 1.1;
  margin-top: 2px !important;
  color: #666;
}

.address-input-group {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-color);
  border-radius: 5px;
  padding: 5px 10px;
  gap: 10px;
  margin: 20px 0px;
}

.radio-btn {
  flex-shrink: 0;
  width: auto;
}

.address-actions {
  margin-left: auto;
}
.address-disabled {
  background-color: #e9e9e9;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.add-new-address-btn {
  display: block;
  margin-top: 10px;
  width: 100%;
  padding: 14px 10px;
  border: 1px dashed #c9c9c9;
  background: none;
  text-align: justify;
  margin-bottom: 10px;
  border-radius: 9px;
  cursor: pointer;
}

.send-to-someone-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  input {
    width: auto;
  }
}

.address-confirm-btn {
  display: block;
  width: 100%;
  text-transform: uppercase;
  padding: 10px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 5px;
  // font-size: 1rem;
  cursor: pointer;
  &.disabled {
    background-color: #e9e9e9;
    color: #cbcbcb;
  }
}

/* Shipping Company Section */

.invoice-detail-section {
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
  opacity: 0;
  max-height: 0px;
  transition:
    opacity 0.5s ease,
    max-height 0.5s ease; /* Smooth transitions */
  &.expanded {
    opacity: 1;
  }
}

.coupon-section.expanded {
  max-height: 70px;
  opacity: 1;
}
.invoice-button {
  display: inline-block;
  padding: 5px 20px;
  font-size: 11px;
  width: 126px;
  align-self: center;
  height: 29px;
  line-height: 15px;
  top: 8px;
  margin-left: -9px;
  margin-right: -9px;
  background-color: white;
  border: 1px solid #dddddd;
  border-radius: 100px;
  position: absolute;
  cursor: pointer;
  outline: none;
}
.customer-info {
  position: relative;
}
.button-container {
  background-color: #f7f9fa;
  height: 34px;
  width: 140px;
  position: absolute;
  bottom: -17px;
  left: 50%;
  border-radius: 20px;
  padding: 15px;
  transform: translateX(-50%);
}
.google-bg-image {
}
.google-address-button {
  border: 1px solid var(--button-color);
  border-radius: 5px;
  padding: 5px 10px;
  justify-content: center;
  background: white;
  flex-direction: row;
  position: absolute;
  width: 90%;
  margin: 0 12px;
  top: 15px;
  .text-select-map {
    font-size: 1rem;
  }
}
.google-bg-img {
  height: 80px;
  width: 100%;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}
.add_new_address_wrapper {
  border: 1px dashed #c9c9c9;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.icon-down {
  margin: 0 3px;
  &.expanded {
    rotate: 180deg;
  }
}
// installments slider
.installment-wrapper {
  border: 1px solid var(--button-color);
  border-radius: 10px;
}
.credit-card-input {
  display: flex;
  direction: ltr;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 15px;
  border: 1px solid var(--button-color);
  border-radius: 5px;
}
.slider-container {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.title {
  font-size: 12px;
  color: #000;
  margin-bottom: 31px;
}

.horizontal-slider {
  height: 4px;
  background: #ddd;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  touch-action: pan-y;
}

.track {
  height: 7px;
  background: #3f51b5;
  border-radius: 7px;
}

.thumb {
  height: 20px;
  width: 20px;
  background-color: #3f51b5;
  border-radius: 50%;
  cursor: grab;
  position: relative;
}

.thumb-value {
  position: absolute;
  text-align: center;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background: #3f51b5;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  line-height: 30px;
  font-weight: bold;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
