.comman-heading {
    font-size: 20px;
    font-weight: 600;
    color: #222;
    margin-bottom: 15px;
    margin-top: 10px;
    text-align: center;
    line-height: 1.2;
}
.review-category-heading {
    text-align: center;
    font-size: 19px !important;
    color: #000;
    margin: 0 0 15px;
    font-weight: 600;
}
.bussiness-review  .ratting-star {
    font-size: 24px;
    display: contents;
    margin: 2px 0 0;
    color: #fdd836;
}
.base-on {
    font-size: 14px !important;
    color: #000;
    margin-top: 0;
    margin-bottom: 15px;
    line-height: 1.1;
    font-weight: 400;
}
.rating-count {
    font-size: 16px !important;
    color: #000;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 500;
    display: block;
    line-height: 1.1;
}