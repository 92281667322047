.input-otp-container {
  direction: ltr;
  margin-top: 10px;
  padding: 0px;
  height: 55px;
  width: 100%;
}
.vi__character--inactive {
  background-color: white;
}
.verification-msg {
  opacity: 0.65;
  font-size: 12px;
  width: 90%;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 3px;
  margin-top: 10px;
}
.input-otp-character {
  font-size: 24px;
  /* border: 0.5px solid black; */
  border-radius: 5px;
  font-size: 20px;
  line-height: 42px;
  height: 41px;
  width: 75px;
  border-radius: 12px;
}

.input-otp-active {
  // outline-style: ;
  outline: 0 !important;
  // outline: 1px 0 solid $theme-color !important;
  color: black;
}

.icon_sm {
  float: left;
  margin-right: 6px;
}
.login-txt {
  color: #000000;
  font-size: 20px;
  text-transform: capitalize;
}
.cart_content_alert {
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  color: var(--button-color);
  padding: 10px 0px;
}
.cart_content_to_marg {
  display: flex;
  flex-direction: column;
}
.mobile-number {
  color: #2e2e2e;
  text-align: center;
  align-self: center;
}
