@import "~bootstrap/scss/bootstrap";
@import "~react-modal-video/scss/modal-video.scss";
@import "~react-multi-carousel/lib/styles.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~react-phone-input-2/lib/style.css";
@import "~@uvarov.frontend/vanilla-calendar/build/vanilla-calendar.min.css";
@import "~react-lazy-load-image-component/src/effects/blur.css";
@import "../css/icons.min.css";
@import "variables";
@import "arabic-styles";
@import "swiper_center_3d";
@import "helper";
@import "common";
@import "TimeSlots";
@import "preloader";
@import "header";
@import "slider";
@import "product_variations";
@import "track-order";
@import "user-address";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";
@import "button-common";
@import "select-address";
@import "_validate-otp";
@import "_thankyou";
@import "../scss/componentsStyles/stepper";
@import "/src/assets/scss/componentsStyles/mobileCartItems";
@import "./componentsStyles/react-input-number";
@import "./componentsStyles/send-otp";
@import "./componentsStyles/applyCoupon";
@import "./componentsStyles/mobile-checkout";
@import "./componentsStyles/receiver-new";
@import"./componentsStyles/payment-timeline";
@import "./componentsStyles/Overlay-Loader";
@import "./componentsStyles/bussiness-review";
@import"../../wrappers/announcement-bar/announcement-bar.scss";
@import "./componentsStyles/desktop-cart";

html[lang="ar"] body {
  direction: rtl;
}

.outer-div ::-webkit-scrollbar {
  display: none;
}

//  ::-webkit-scrollbar {display:none;}

.border-error {
  border: 1px solid red !important;
}
.error-with-box {
  padding: 10px;
  border-radius: 5px;
}
.brand-logo-active .swiper-autoheight .swiper-wrapper {
  align-items: center !important;
  justify-content: center !important;
  @media only screen and (max-width: 600px) {
    justify-content: normal !important;
  }
}
.modal-content {
  border-radius: 15px;
  @media #{$xs-layout} {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
// html[lang="ar"].header-right-wrap,
//   .same-style.cart-wrap,
//   .shopping-cart-content.cart-visible,
// .header-right-wrap .same-style.cart-wrap .shopping-cart-content.active,
// .header-right-wrap,
//   .same-style.header-wishlist,
//   .shopping-cart-content.cart-visible,
// .header-right-wrap, .same-style.header-wishlist .shopping-cart-content.active,
// .header-right-wrap,
//   .same-style.header-compare,
//   .shopping-cart-content.cart-visible,
// .header-right-wrap .same-style.header-compare .shopping-cart-content.active {
//   left: 0 !important;
//   right: unset !important;
// }
/* Apply styles only when lang attribute is 'ar' */
//shopping-cart-content

.react-multiple-carousel__arrow {
  min-width: 24px !important;
  padding: 0px;
  margin: 0px;
  min-height: 24px !important;
  z-index: 998 !important;
  height: 100%;
  border-radius: 0;
  border-radius: initial;
  background: #9fc5ea !important;
}
.react-multiple-carousel__arrow:disabled {
  cursor: default;
  // 698dae
  background: #d1dfec !important;
  box-shadow: unset;
  // background: rgba(0, 0, 0, 0.5);
  // / background: yellow; /
}
.rtl.react-multiple-carousel__arrow:disabled {
  box-shadow: unset;
  // background: rgba(0, 0, 0, 0.5);
  // / background: yellow; /
}
.react-multiple-carousel__arrow:disabled::before {
  color: #698dae;
}
// ::before
.react-multiple-carousel__arrow--left {
  // left: calc(4% + 1px);
  height: 100%;
  // box-shadow: 4px -2px 3px 1px rgba(203,221,237,0.57);
  box-shadow: 4px 2px 7px 1px #9fc5ea7d;
}
.rtl.react-multiple-carousel__arrow--left {
  left: auto;
  right: calc(0% + 0px);
  box-shadow: -4px 2px 7px 1px #9fc5ea7d;
}
.rtl.react-multiple-carousel__arrow--right {
  right: auto;
  left: calc(0% + 0px);
  // 9px -2px 4px -5px #9fc5ea7d
  box-shadow: 4px 2px 7px 1px #9fc5ea7d;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 0px);
  // box-shadow: -4px 0px 1px -1px #9fc5ea7d;
  box-shadow: 4px 2px 7px 1px #9fc5ea7d;
  // -webkit-box-shadow: -9px -2px 4px -5px #9fc5ea7d;
  // -moz-box-shadow: -9px -2px 4px -5px #9fc5ea7d;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 0px);
}

.react-multiple-carousel__arrow::before {
  font-size: 16px !important;
  color: #000;
}
.tab-content > .active {
  display: flex;
  flex-direction: column;
}
.slider-area .swiper-pagination-bullets {
  left: 50%;
  width: auto !important;
  transform: translate(-50%);
}
table.MsoNormalTable {
  width: 100% !important;
}
