

// *,
// *::before,
// *::after {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   list-style-type: none;
// }

// :root {
//   --primary: #6a59ff;
//   --white: #ffffff;
//   --bg: #f5f5f5;
// }

html {
  font-size: 68%;
  scroll-behavior: smooth;
}


.container-swiper-3d {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}
.cat-swiper-button-nav {
    font-size: 80px;
    position: absolute;
    // top: 38%;
    // left: 80px;
    display: inline-block;
    // visibility: hidden;
    width: 80px;
    height: 80px;
    padding: 0;
    transition: all 0.3s ease 0s;
    transform: translateY(-50%);
    text-align: center;
    // opacity: 0;
    color: #999;
    border: none;
    background: none;
}

.featued-category .swiper-pagination-bullets {
    position: absolute;
    // right: 0;
    bottom: -8px;
    left: 45%;
    text-align: center;
    // background: #000000;
    border-radius: 20px;
    text-align: center;
    @media #{$xxs-layout} {
        left: 30%;
    }
}
.featued-category .swiper-pagination-bullets .swiper-pagination-bullet {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    // margin: 0 8px;
    opacity: 1;
    border-radius: 50px;
    background: #bcbcbc none repeat scroll 0 0;
}
.featued-category .swiper-pagination-bullets .swiper-pagination-bullet:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    content: "";
    border: 1px solid transparent;
    border-radius: 50px;
}
.featued-category  .swiper-pagination-bullets .swiper-pagination-bullet-active:before {
    background: #000;
    border: 1px solid #fff;
}
.cat-swiper-button-nav:after {
    font-size: 0;
}
// .heading {
//   padding: 1rem 0;
//   font-size: 3.5rem;
//   text-align: center;
// }

.category_swiper_container {
//   height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.category-swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}

@media (max-width: 500px) {
  .category_swiper_container {
    // height: 47rem;
  }
  .category-swiper-slide {
    width: 28rem !important;
    height: 482px !important;
  }
  .category-swiper-slide img {
    width: 310px !important;
    height: 482px !important;
  }
}

.category-swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 8px;
//   border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
