.payment-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; // Allows wrapping for smaller screens
  gap: 20px;
  padding: 20px;
}

.payment-item {
  text-align: center;
  display: flex;
  /* flex: 1 1 100px; */
  max-width: 120px;
  align-items: center;
  flex-direction: column;
}

.circle {
  width: 10vw;
  height: 10vw;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  border: 2px solid var(--button-color);
  background: white;
  position: relative;
}

/* Responsive Text */
.amount,
.label {
  font-size: 0.8vw; // Scales based on screen size
}

/* Quarter Pie (25%) */
.quarter-pie {
  background: conic-gradient(var(--button-color) 0deg 90deg, white 90deg 360deg);
}

/* Half Circle (50%) */
.half-circle {
  background: conic-gradient(var(--button-color) 0deg 180deg, white 180deg 360deg);
}

/* Three-Quarter Pie (75%) */
.three-quarter-pie {
  background: conic-gradient(var(--button-color) 0deg 270deg, white 270deg 360deg);
}

/* Full Circle (100%) */
.full-circle {
  background: var(--button-color);
}

/* 📱 Mobile Optimization */
@media (max-width: 600px) {
  .payment-timeline {
    // flex-direction: column; // Stacks elements in a column
    align-items: center;
  }

  .circle {
    width: 40px;
    height: 40px;
  }

  .amount,
  .label {
    font-size: 10px; // Further reduced font size for small screens
  }
}
