.cart-container {
  max-width: 800px;
  margin: auto;
  border-radius: 9px;
  background: #ffffff;
  margin-bottom: 15px;
  padding: 20px 20px 12px;
  position: relative;
}
.item-container {
  // height: 300px;
  // overflow: scroll;
  margin-bottom: 10px;
}
.cart-item {
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.item-details {
  flex: 2;
}

.item-details h3 {
  font-size: 14px;
  color: #000000;
  line-height: 1.5;
  width: 90%;
  // margin: 0;
}

.item-details h4 {
  font-size: 10px;
  margin: 5px 0;
  color: #939393;
}
.delete-icon {
  margin-left: 5px;
  margin-right: 5px;
  position: absolute;
  left: 1px;
  margin-top: -19px;
}
.price {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--button-color);
  .old {
      margin-right: 10px;
  
      text-decoration: line-through;
  
      color: #8e8e8e;
    }
}

.mrp {
  text-decoration: line-through;
  color: red;
  margin-right: 10px;
}

.delivery {
  color: green;
  font-size: 12px;
  margin-top: 5px;
}

.item-image {
  // flex: 1;
  text-align: center;
  margin-inline-end: 10px;
}

.item-image img {
  max-width: 70px;
  max-height: 70px;
  object-position: center;
  display: block;
  object-fit: cover;
  margin: auto;
}
.cart-item-bottom {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 0.5px solid #dfdfdf;
  border-bottom: 0.5px solid #dfdfdf;
  justify-content: space-between;
}
.item-quantity {
  border: 1px solid #ddd;
  padding: 0px 11px 0px 11px;
  width: 12rem;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  &.mobile-input {
    width: 100%;
  }
}

.quantity-button {
  background: white;
  color: #0c5ca8;
  border: 1px solid #0c5ca8;
  /* border: none; */
  border-radius: 50px;
  padding: 1px 10px;
  cursor: pointer;
  margin: 0 5px;
}

.payment-details {
  //   border-top: 2px solid #ddd;
  padding: 10px 15px;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 9px;
}

.payment-row {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 5px 0;

  .title {
    color: #757575;
  }
}
.txt-order-summary {
  font-size: 14px;
  font-weight: 600;
}
.item-quantity-text {
  text-align: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 5px 0px;
  width: 4rem;
  &.mobile-text {
    width: 80%;
  }
}
.total {
  font-size: 16px;
  font-weight: bold;
  margin-top: 18px;
  border-top: 0.5px solid #dfdfdf;
  padding-top: 15px;
}

html[lang="en"] .delete-icon {
  left: auto;
  right: 10px;
}
