/*------- 6. Product style  --------*/
.brand-logo-img {
  margin-top: 10px;
  max-width: 60px;
}
.product-view-details {
  button {
    border: none !important;
    background: none !important;
  }
  .add-to-cart-btn {
    align-items: center;
    display: flex;
    color: var(--button-color);
    align-items: flex-start;
    gap: 4px;
  }
     
  .full-color-button {
    color: white !important;
  }
    .btn-disabled {
      background: white !important
    }
}
.product-area {
  position: relative;
  background: $bg-grey;
  .row {
    &.three-column {
      .col-xl-3 {
        flex: 1 0 33.3333%;

        max-width: 33.3333%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
    &.five-column {
      .col-xl-3 {
        flex: 1 0 20%;

        max-width: 20%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;

          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }
  }

  &--style2 {
    .swiper-slider-navigation-wrapper {
      width: 100%;

      text-align: center;
    }
    .ht-swiper-button-nav {
      font-size: 28px;
      line-height: 1;

      position: absolute;
      top: 15px;
      right: 0;

      display: inline-block;

      width: 35px;
      height: 35px;
      padding: 0;

      transition: all 0.3s ease 0s;
      text-align: center;

      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #f9f9f9;
      i {
        padding-top: 3px;
      }
      @media #{$xs-layout} {
        margin-top: 15px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;
      }
      &.swiper-button-prev {
        right: 40px;
        left: auto;
        @media #{$xs-layout} {
          margin-right: 10px;
        }
      }

      @media #{$xs-layout} {
        position: static;

        text-align: center;
      }
    }
  }
}

.product-tab-list {
  // margin-left: -15px;
  // margin-right: -15px;
  // background-color: white;

  justify-content: center;
  &.pt-30,
  &.pt-55 {
    @media #{$xs-layout} {
      padding: 10px 0 30px;
    }
  }
  @media #{$md-layout} {
    &.pt-35,
    &.pt-60 {
      padding: 20px 0 30px;
    }
  }
  @media #{$xs-layout} {
    &.pt-35,
    &.pt-60 {
      padding: 10px 0 10px;
    }
  }
  .prd-nav-title {
    padding: auto;
    color: #939393;
  }
  a {
    padding: 5px 27px;
    margin: 0 11px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 11px 5px;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;

      transition: all 0.3s ease 0s;

      color: #555;
      &:hover {
        color: #000;
      }
    }
    &.active h4 {
      color: #000;
      padding-bottom: 3px;
      border-bottom: 2px solid #000;
    }
    &.active {
      border: 1px solid var(--button-color);
      border-radius: 20px;
      background-color: white !important;
    }
    &.active {
      .prd-nav-title {
        color: var(--button-color) !important;
      }
    }
  }
  &.product-tab-fruits {
    a {
      h4 {
        color: #000000;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #6eab49;
        }
      }
      &.active h4 {
        color: #6eab49;
        border-bottom: 2px solid #6eab49;
      }
    }
  }
  &.product-tab-pink {
    a {
      h4 {
        color: #000000;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #e90042;
        }
      }
      &.active h4 {
        color: #e90042;
        border-bottom: 2px solid #e90042;
      }
    }
  }
  &.product-tab-pink2 {
    a {
      h4 {
        color: #000000;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #ed59a0;
        }
      }
      &.active h4 {
        color: #ed59a0;
        border-bottom: 2px solid ed59a0e90042;
      }
    }
  }

  &.product-tab-white {
    a {
      h4 {
        color: #fff;
      }
    }
  }

  &--style2 {
    .nav-item {
      a {
        margin: 0;
        margin-left: 20px;
        padding: 0;

        text-transform: uppercase;
        &.active {
          h4 {
            color: #fed700;
          }
        }
        h4 {
          font-weight: 700;

          color: #5f5f5f;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }
    }

    @media #{$md-layout,
    $xs-layout} {
      margin-top: 10px;
    }
  }
}

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    // max-height: 368px;
    height: auto;
    position: relative;
    overflow: hidden;
    max-height: 320px;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: var(--button-color);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;
      visibility: hidden;
      transition-duration: 0.7s;
      transform: translate(-50%, 20px);
      opacity: 0;
    }
    .product-action {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 50%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      transform: translateX(-50%);
      @media #{$xxs-layout} {
        opacity: 0;
      }
      > div {
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: center;

        height: 48px;
        transition: all 0.5s ease;
        transform: translateY(20px);

        opacity: 0;
        background-color: var(--button-color);
        // &:hover {
        //     background-color: #000;
        // }
      }
      .pro-wishlist {
        width: 48px;

        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;

        width: calc(100% - 48px - 48px);

        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        margin: 0;

        transition-delay: 0.2s;

        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-same-action {
        a,
        button {
          font-size: 16px;
          line-height: 48px;

          display: block;

          width: 100%;
          height: 48px;

          text-align: center;
          text-transform: capitalize;

          color: #fff;
          border: none;
          background: none;
          &:hover,
          &.active {
            background-color: #000;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
    @media #{$xxs-layout} {
      height: auto !important;
      // object-fit: cover;
    }
  }

  .product-content {
    // margin: 20px 0 0;
    .product-name {
      font-size: 14px;
      line-height: 1.4;
      font-weight: 400;
      margin: 0;
      @media #{$xxs-layout} {
        // font-size: 12px !important;
        // line-height: 1.4;
      }
    }
    @media #{$xxs-layout} {
      text-align: unset !important;
    }
    .product-rating {
      margin: 3px 0 3px;
      i {
        font-size: 17px;

        margin: 0 3px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      span {
        font-size: 15px;
        font-weight: 500;

        position: relative;

        margin: 0 9px;

        color: #000;
        &::before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 7px;
          height: 2px;

          content: "";

          background-color: #000;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #8e8e8e;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }

  &:hover .product-action > div {
    visibility: visible;

    transform: translateY(0);

    opacity: 1;
  }
}

.modal-dialog {
  // width: 960px;
  // max-width: 960px;
  margin: 8% auto 0;
  // padding: 35px;
  @media #{$md-layout} {
    // width: 720px;
    // max-width: 720px;
  }
  @media #{$xs-layout} {
    width: 100%;
    max-width: 100%;
    // padding: 35px 0;
    bottom: 0;
    position: fixed;
  }
  .modal-header {
    .close {
      cursor: pointer;

      opacity: 1;
      color: #333;
      &:hover {
        color: $theme-color;
      }
    }
  }
  .modal-body {
    padding: 35px 15px;
  }
  .quickview-big-img {
    img {
      width: 100%;
    }
  }
}

.product-details-content {
  @media #{$lg-layout} {
    &.ml-70 {
      // margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      // margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-top: 30px;
      // margin-left: 0;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h1 {
    font-size: 24px;
    line-height: 1;

    margin: 0 15px 0;

    color: #010101;
  }
  .product-name {
    font-size: 20px;
    margin: 0 15px;
    line-height: 1.5;
  }
  .product-details-price {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: var(--button-color);
    margin: 15px 15px 26px;
    span {
      // font-size: 20px;
      // color: var(--button-color);
      // color: #fe5252;
      &.old {
        font-size: var(--old-price-font-size);

        margin-left: 20px;

        text-decoration: line-through;

        color: var(--old-price-color) !important;
      }
    }
  }
  .pro-details-rating-wrap {
    display: flex;
    align-items: center;

    margin: 0 0 17px;
    .pro-details-rating {
      position: relative;

      margin-right: 39px;
      &:before {
        position: absolute;
        top: 4px;
        right: -19px;

        display: none;

        width: 2px;
        height: 15px;

        content: "";

        background-color: #d1d1d1;
      }
      i {
        font-size: 17px;

        margin-right: 5px;

        color: #5f5d5d;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    span {
      a {
        font-size: 15px;

        color: $theme-color;
        &:hover {
          color: #000;
        }
      }
    }
  }
  pre {
    font-size: 15px;
    line-height: 28px;
    white-space: pre-line;
    margin: 0;

    color: #333;
  }

  .pro-details-list {
    margin: 20px 15px;
    // padding: 0 0 37px;

    // border-bottom: 1px solid #e5e5e5;
    ul {
      li {
        margin: 0 0 5px;

        color: #333;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .pro-details-size-color {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .pro-details-color-wrap {
      margin-right: 20px;
      @media #{$xs-layout} {
        margin-bottom: 20px;
      }
      @media #{$sm-layout} {
        margin-bottom: 0;
      }
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 15px;
      }
      .pro-details-color-content {
        ul {
          li {
            position: relative;

            display: block;
            float: left;

            width: 14px;
            height: 14px;
            margin-right: 15px;

            cursor: pointer;
            transition: all 0.4s ease 0s;

            border-radius: 50px;
            &:last-child {
              margin-right: 0;
            }
            &.blue {
              border: 1px solid #4798f3;
              background-color: #4798f3;
            }
            &.maroon {
              border: 1px solid #736751;
              background-color: #736751;
            }
            &.gray {
              border: 1px solid #c0c0c0;
              background-color: #c0c0c0;
            }
            &.green {
              border: 1px solid #139c57;
              background-color: #139c57;
            }
            &.yellow {
              border: 1px solid #e28b37;
              background-color: #e28b37;
            }
          }
        }
        &--single {
          position: relative;

          display: inline-block;

          width: 14px;
          height: 14px;
          margin-right: 15px;

          cursor: pointer;
          transition: all 0.4s ease 0s;

          border-radius: 50px;
          &:last-child {
            margin-right: 0;
          }
          input {
            position: absolute;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
          &.blue {
            border: 1px solid #4798f3;
            background-color: #4798f3;
          }
          &.maroon {
            border: 1px solid #736751;
            background-color: #736751;
          }
          &.gray {
            border: 1px solid #c0c0c0;
            background-color: #c0c0c0;
          }
          &.green {
            border: 1px solid #139c57;
            background-color: #139c57;
          }
          &.yellow {
            border: 1px solid #e28b37;
            background-color: #e28b37;
          }
          &.white {
            border: 1px solid #333;
            background-color: #fff;
          }
          &.black {
            border: 1px solid #333;
            background-color: #333;
          }
          &.brown {
            border: 1px solid brown;
            background-color: brown;
          }
          .checkmark {
            position: relative;

            display: inline-block;

            width: 14px;
            height: 14px;
            margin: 0;
            &:after {
              position: absolute;
              top: -7px;
              left: -4px;

              visibility: hidden;

              width: 20px;
              height: 20px;

              content: "";
              transition: 0.3s;

              opacity: 0;
              border: 2px solid #333;
              border-radius: 50%;
            }
          }

          &:hover {
            input {
              & ~ .checkmark {
                &:after {
                  visibility: visible;

                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .pro-details-size {
      span {
        font-size: 15px;
        font-weight: 500;

        display: block;

        margin: 0 0 10px;
      }
      .pro-details-size-content {
        ul {
          li {
            display: inline-block;

            margin: 0 2px 0 0;

            list-style: outside none none;
            a {
              font-size: 12px;
              line-height: 1;

              display: inline-block;

              padding: 6px 9px 7px;

              text-transform: uppercase;

              color: #000;
              background-color: #f1f2f6;
              &:hover {
                color: #fff;
                background-color: $theme-color;
              }
            }
          }
        }
        &--single {
          font-size: 12px;
          line-height: 1;

          position: relative;

          display: inline-block;

          margin: 0 5px 0 0;

          text-transform: uppercase;

          color: #000;
          background-color: #f1f2f6;
          &:hover {
            color: #fff;
            background-color: $theme-color;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;

            cursor: pointer;

            opacity: 0;

            &:checked {
              & ~ .size-name {
                color: $white;
                background-color: $theme-color;
              }
            }
          }
          .size-name {
            font-size: 12px;
            font-weight: 400;

            width: 100%;
            margin-bottom: 0;
            padding: 8px;
          }
        }
      }
    }
  }
  .pro-details-quality {
    display: flex;
    // align-items: center;
    margin-top: 40px;
    margin-bottom: 34px;
    padding: 20px;
    background: white;
    border-radius: 10px;

    .cart-plus-minus {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 80px;
      height: 60px;
      padding: 0;
      border: 1px solid #e8e8e8;
      .qtybutton {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        position: absolute;

        float: inherit;

        width: 24px;
        margin: 0;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: #8f8f8f;
        border: none;
        background: none;
      }
      .dec.qtybutton {
        top: 0;
        left: 0;

        height: 60px;
      }
      .inc.qtybutton {
        top: 0;
        right: 0;

        height: 60px;
      }
      input.cart-plus-minus-box {
        font-size: 14px;

        float: left;

        width: 80px;
        height: 60px;
        margin: 0;
        padding: 0;

        text-align: center;

        color: #8f8f8f;
        border: medium none;
        background: transparent none repeat scroll 0 0;
      }
    }
    .pro-details-cart {
      // margin: 0 25px 0 10px;
      @media #{$xs-layout} {
        // margin: 0 10px 0 10px;
      }
      @media #{$md-layout} {
        // margin: 0 17px 0 10px;
      }
      a,
      button {
        font-weight: bold;
        line-height: 1;
        z-index: 99;
        border-radius: 5px;
        display: inline-block;
        font-size: 12px;
        padding: 11px 28px 11px;
        text-transform: uppercase;
        color: #fff;

        border: none;
        background: none;
        background-color: var(--button-color);
        @media #{$xs-layout} {
          // padding: 23px 12px 23px;
          padding: 11px 28px 11px;
        }
        @media #{$md-layout} {
          padding: 22px 22px 22px;
        }
        &:hover {
          border: none;
        }

        &:disabled {
          cursor: not-allowed;
          &:before,
          &:after {
            display: none;
          }
        }
        &.add-to-cart-mobile {
          background: var(--button-color);
          width: 100%;
          border: 1px solid var(--button-color);
          // color: var(--button-color);
          color: white;
          height: 40px;
          gap: 4px;
          justify-content: center;
          display: flex;
          align-items: flex-end;
        }
        &.btn-buy-now {
          background: white;
          border: 1px solid var(--button-color);
          height: 40px;
          color: var(--button-color);
          width: 90%;
          text-wrap: nowrap;
        }
      }
      &.mobile-pro-details-cart {
        margin: 0px;
      }
    }
    .pro-details-wishlist {
      a,
      button {
        font-size: 18px;

        color: #000;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
    .pro-details-compare {
      a,
      button {
        font-size: 18px;

        margin-left: 25px;

        color: #000;
        border: none;
        background: none;
        @media #{$xs-layout} {
          margin-left: 10px;
        }
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  .mobile {
    position: fixed;
    background: white;
    bottom: 0;
    z-index: 100;
    margin: 0;
    padding: 12px 22px;
    flex-direction: column;
    width: 96%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .pro-details-meta {
    display: flex;

    margin: 0 0 10px;
    span {
      font-size: 15px;

      margin-right: 5px;

      color: #676767;
    }
    ul {
      li {
        display: inline-block;

        margin: 0 10px 0 0;
        a {
          font-size: 15px;

          color: #676767;
          @media #{$xs-layout} {
            font-size: 14px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .pro-details-social {
    margin: 24px 0 0;
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 16px;

          color: #343538;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &.pro-details-slider-content {
    @media #{$xs-layout} {
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .product-details-price {
      justify-content: center;
    }
    p {
      width: 59%;
      margin: 0 auto;
      @media #{$lg-layout} {
        width: 80%;
      }
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$xs-layout} {
        width: 100%;
      }
    }
    .pro-details-rating-wrap {
      justify-content: center;
    }
    .pro-details-size-color {
      justify-content: center;

      text-align: left;
      @media #{$xs-layout} {
        text-align: center;
        & .pro-details-color-content {
          display: flex;
          justify-content: center;
        }
      }
    }
    .pro-details-quality {
      justify-content: center;
    }
    .pro-details-meta {
      justify-content: center;
    }
    .pro-details-social {
      ul {
        justify-content: center;
      }
    }
  }
}

.quickview-wrap {
  .nav-style-1.owl-carousel > .owl-nav div {
    font-size: 20px;

    left: 0;

    color: #333;
    &:hover {
      color: $theme-color;
    }
    &.owl-next {
      right: 0;
      left: auto;
    }
  }
  &:hover .nav-style-1.owl-carousel > .owl-nav div {
    visibility: visible;

    opacity: 1;
  }
}

/*  prodduct 2     */

.tab-filter-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$xs-layout} {
    display: block;
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
  @media #{$sm-layout} {
    display: flex;
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
}

.product-tab-list-2 {
  @media #{$xs-layout} {
    margin: 0 0 10px;
  }
  @media #{$sm-layout} {
    margin: 0 0 0;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }

  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #555;
      border-bottom: 2px solid transparent;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      h4 {
        color: #000000;
        border-bottom: 2px solid #000000;
      }
    }
  }
}

.filter-active {
  position: relative;
  a,
  button {
    font-size: 18px;
    font-weight: 500;

    color: #010101;
    border: none;
    background: none;
    &:hover,
    &.active {
      color: $theme-color;
    }
    i {
      font-size: 14px;
    }
  }
}
.option-button-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.option-button-inner {
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 4px 14px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  width: 100%;
  /* max-width: 17.33%; */
}
.unavailable {
  background-color: #f7f7f7;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}
.unavailable-img {
  filter: brightness(0.5);
  cursor: not-allowed;
}
.unavailable-img-txt {
  color: #999;
}

// .unavailable ::before {

// }

.option-selected {
  background: var(--mouse-selection-color);
  color: white;
  font-size: 14px;
}
.option-selected-img {
  background: var(--mouse-selection-color);
  color: white;
  font-size: 14px;
  border: 1px solid black;
}
.option-multi-select {
  width: 100%;
  border: 0px;
  padding: 10px;
  font-size: 14px;
}
.product-filter-wrapper {
  overflow: hidden;

  height: 0;

  transition: height 0.4s ease-out;

  background-color: #fff;

  .product-filter {
    h5 {
      font-size: 16px;
      font-weight: 500;

      position: relative;

      margin-bottom: 25px;
      padding-bottom: 7px;

      text-transform: capitalize;

      color: #000;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 20px;
        height: 1px;

        content: "";

        background-color: #000;
      }
    }
    ul.sort-by {
      li {
        margin-bottom: 3px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
        }
      }
    }
    ul.color-filter {
      li {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
        input {
          display: inline-block;
          float: left;

          width: 20px;
          height: 20px;
          margin: 3px 8px 0 0;
        }
        a {
          text-transform: capitalize;
        }
      }
    }
    .product-tags {
      ul {
        li {
          margin-bottom: 4px;
          a {
            text-transform: capitalize;
          }
        }
      }
    }

    ul {
      li {
        button {
          text-transform: capitalize;

          border: none;
          background: none;
          &:hover,
          &.active {
            color: var(--mouse-selection-color);
          }
        }
      }
    }

    &--tag {
      ul {
        li {
          display: inline-block;
          button {
            margin-right: 10px;
            margin-bottom: 10px;

            text-transform: lowercase;

            border: 1px solid #e6e6e6;
            &:hover {
              border-color: $theme-color;
            }
          }
        }
      }
    }
  }

  &__inner {
    margin-bottom: 60px;
    padding: 45px 45px 7px;

    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    @media #{$md-layout} {
      padding: 35px 25px 7px;
    }
    @media #{$xs-layout} {
      padding: 35px 25px 7px;
    }
  }
}
.price-filter {
  .price-slider-amount {
    input {
      font-size: 16px;
      font-weight: 500;

      padding: 0;

      border: none;
      background: transparent;
    }
  }
  #slider-range {
    height: 5px;
    margin-bottom: 12px;
    margin-left: auto;

    border: medium none;
    border-radius: 50px;
    background: #dbdbdb none repeat scroll 0 0;
    span {
      transition: all 0s ease 0s;
    }
    .ui-slider-range {
      position: absolute;

      display: block;

      transition: all 0s ease 0s;

      border: none;
      background: #fa6bff none repeat scroll 0 0;
    }
    .ui-slider-handle {
      width: 15px;
      height: 15px;
      margin-left: 0;

      border: medium none;
      border: 4px solid #fa6bff;
      border-radius: 50%;
      background: #fff none repeat scroll 0 0;
    }
  }
}
.tab-content.jump {
  .tab-pane {
    display: block;
    overflow: hidden;

    height: 0;

    opacity: 0;
    &.active {
      display: block;
      overflow: visible;

      height: auto;

      opacity: 1;
    }
  }
}
.product-list-single {
  width: 100%;
  max-height: 368px !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &:hover .hover-img-single {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }
  &:hover .default-img-single {
    visibility: hidden;

    transform: translateX(-100%);
  }
}

.product-wrap-2 {
  position: relative;
  overflow: hidden;
  padding: 10px 12px;
  .price-2 {
    span {
      font-size: var(--old-price-font-size) ;
      position: relative;
      // color: black;

      &.old {
        text-decoration: line-through;
        color: var(--old-price-color);
      }
    }
  }
  .brand-name-wrapper {
    min-height: 160px;
  }
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      top: 20px;
      right: 20px;
      &.pink {
        color: #fa6bff;
      }
      &.red {
        color: #ff3d2a;
      }
      &.purple {
        color: $theme-color;
      }
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }

    .product-action-2 {
      position: absolute;
      top: 50%;

      display: flex;
      justify-content: center;

      width: 100%;

      transform: translateY(-50%);
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: var(--mouse-selection-color);
        &:hover,
        &.active {
          background-color: #fa6bff;
        }
      }
      &.product-action-2-red {
        a {
          background-color: #df262b;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;
        &.pink {
          color: #fa6bff;
        }
        &.purple {
          color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .product-list-single {
      width: 100%;
      max-height: 460px;
      // background-repeat: no-repeat;
      object-fit: contain;
      object-position: center;
    }
  }
  .fav-mobile-button {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    margin-bottom: 5px;
    button {
      font-size: 16px;
      display: inline-block;
      margin: 4px 0 0 10px;
      color: #929292;
      border: none;
      background: none;
      &:hover,
      &.active {
        color: $theme-color;
      }
    }
  }
  .brand-name-wrapper {
    min-height: 160px;
  }
  .product-content {
    min-height: 111px;
    margin-top: 10px;
  }

  .full-color-button {
    background: var(--button-color) !important;
    color: white !important;
  }
        .btn-disabled {
          background: white !important;
          border: 1px solid #b4b8c0 !important;
            color: #b4b8c0 !important;
        }
  .product-view-details {
    color: var(--button-color);
    padding: 7px 8px;
    margin-top: 10px;
    width: 100%;
    gap: 4px;
    display: flex;
    background: white;
    border-radius: 10px;
    text-align: center;
    border: 1px solid var(--button-color);
    font-size: 12px;
    align-items: center;
    justify-content: center;
    height: 46px;
    a {
      color: var(--button-color);
    }
  }
  & .full-width-product {
    width: 50%;
    // align-self: flex-end;
  }
  //abbdullah styles
  .mobile-price {
    color: var(--button-color);
    font-size: 14px !important;
  }
  .product-offer-text {
    color: #0c5ca8;
    font-size: 10px;
  }
  .product-model-text {
    font-size: 10px;
    color: #939393;
  }
  //abbdullah styles
  .product-content-2 {
    display: flex;
    justify-content: space-between;
    min-height: 120px;
    // margin: 20px 0 0;
    .title-price-wrap-2 {
      h2 {
        line-height: 1;
        margin: 12px 0 6px;
        a {
          font-size: 12px;

          color: #000000;
          &:hover {
            color: $theme-color;
          }
          @media #{$xs-layout} {
            font-size: 10px;
          }
        }
      }
      .price-2 {
        span {
          // font-size: 14px;
          position: relative;
          // margin-right: 5px;
          &.old {
            text-decoration: line-through;

            color: #fa6bff;
            &.red {
              color: #ff3d2a;
            }
          }
          &:last-child:before {
            display: none;
          }
        }
      }

      &.title-price-wrap-2-white {
        h3 {
          a {
            color: #fff;
            &:hover {
              color: $theme-color;
            }
          }
        }
        .price-2 {
          span {
            color: #fff;
            &.old {
              text-decoration: line-through;

              color: #fa6bff;
            }
          }
        }
      }
    }
    .pro-wishlist-2 {
      a,
      button {
        font-size: 16px;

        display: inline-block;

        margin: 4px 0 0 10px;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }

    @media #{$xs-layout} {
      margin: 0 0 0 0;
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }
  &:hover .default-img {
    visibility: hidden;

    transform: translateX(-100%);
  }
  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
  &.pro-glaucous-color {
    .product-img {
      span.glaucous {
        color: #3d6882;
      }
      .product-action-2 {
        a,
        button {
          background-color: $theme-color;
          &:hover,
          &.active {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }
    .product-content-2 {
      .pro-wishlist-2 a:hover {
        color: #3d6882;
      }
      .title-price-wrap-2 {
        h3 a:hover {
          color: #3d6882;
        }
        .price-2 {
          span.old {
            color: var(--old-price-color);
            font-size: 12px;
          }
        }
      }
    }
  }

  &.pro-puce-color {
    .product-img {
      span.puce {
        color: #de3e3e;
      }
      .product-action-2 {
        a,
        button {
          background-color: #de3e3e;
          &:hover {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }
    .product-content-2 {
      .pro-wishlist-2 a:hover,
      .pro-wishlist-2 button:hover {
        color: #de3e3e;
      }
      .title-price-wrap-2 {
        h3 a:hover {
          color: #de3e3e;
        }
        .price-2 {
          span.old {
            color: #de3e3e;
          }
        }
      }
    }
  }
}

/* product hm3 */

.section-padding-1 .container-fluid {
  padding: 0 70px;
  @media #{$xl-layout} {
    padding: 0 15px;
  }
  @media #{$lg-layout} {
    padding: 0 30px;
  }
  @media #{$md-layout} {
    padding: 0 40px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}

/* product hm4 */

.hm4-section-padding .container-fluid {
  padding: 0 70px;
  @media #{$xx-layout} {
    padding: 0 60px;
  }
  @media #{$xl-layout} {
    padding: 0 30px;
  }
  @media #{$lg-layout} {
    padding: 0 15px;
  }
  @media #{$md-layout} {
    padding: 0 30px;
  }
  @media #{$xs-layout} {
    padding: 0 15px;
  }
}
.toggle-item-active,
.toggle-item-active2,
.toggle-item-active3,
.toggle-item-active4,
.toggle-item-active5,
.toggle-item-active6 {
  display: none;
}

.view-more {
  display: block;

  width: 100%;

  text-align: center;
  a {
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding-bottom: 2px;

    text-align: center;

    color: #555;
    border-bottom: 1px solid #cccccc;
    &:hover {
      color: $theme-color;
      border-bottom: 1px solid $theme-color;
    }
  }
  @media #{$xs-layout} {
    &.mt-20 {
      margin-top: 0;
    }
  }

  &.round-btn {
    a {
      font-size: 16px;
      line-height: 1;

      display: inline-block;

      padding: 16px 40px 16px 40px;

      text-transform: capitalize;

      color: #fff;
      border-radius: 34px 34px 34px 34px;
      background-color: #c61a32;

      &:hover {
        background-color: $theme-color;
      }
    }
  }
}

.hatbox-opt-itm {
  margin: 0.5rem 3px !important;
  max-width: 25.8%;
}
/* product hm5 */

.hm5-section-padding {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  div[class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.product-wrap-3 {
  position: relative;

  .product-img {
    position: relative;
    a {
      img {
        width: 100%;
      }
    }
    &-badges {
      position: absolute;
      z-index: 9;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #fff;
        border-radius: 3px;
        &.purple {
          background-color: $theme-color;
        }
        &.pink {
          background-color: #fa6bff;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-3-wrap {
    position: absolute;
    z-index: 8;
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;

    visibility: hidden;

    width: 90%;
    height: 90%;

    transition: all 0.4s ease 0s;
    transform: scale(0.85);

    opacity: 0;
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    .product-content-3 {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 100%;

      transition: all 0.4s ease 0s;
      transform: translate(-50%, -50%);
      text-align: center;
      .product-title {
        h3 {
          font-size: 18px;
          line-height: 1;

          margin: 0;
        }
      }
      .price-3 {
        margin: 10px 0 20px;
        span {
          font-size: 16px;
          font-weight: 500;

          position: relative;

          margin: 0 9px;

          color: #000;
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 7px;
            height: 2px;

            content: "";

            background-color: #000;
          }
          &:last-child::before {
            display: none;
          }
          &.old {
            text-decoration: line-through;

            color: #8e8e8e;
          }
        }
      }
      .product-action-3 {
        .pro-same-action {
          display: inline-block;
        }
        a,
        button {
          font-size: 16px;
          line-height: 42px;

          display: inline-block;

          width: 42px;
          height: 42px;
          margin: 0 3px;

          transition: all 0.4s ease-in-out;
          text-align: center;

          color: #fff;
          border: none;
          border-radius: 50px;
          background: none;
          background-color: #a749ff;
          &:hover,
          &.active {
            background-color: #fa6bff;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  &:hover .product-content-3-wrap {
    visibility: visible;

    transform: scale(1);

    opacity: 1;
  }
}

/* product hm6 */

.hm6-section-padding {
  .container-fluid {
    padding-right: 70px;
    padding-left: 70px;
    @media #{$xl-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$lg-layout} {
      padding-right: 30px;
      padding-left: 30px;
    }
    @media #{$md-layout} {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  div[class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.product-wrap-4 {
  position: relative;

  overflow: hidden;
  a {
    img {
      width: 100%;

      transition: all 0.5s ease 0s;
      transform: scale(1);
    }
  }
  .position-1 {
    position: absolute;
    right: 0;
    bottom: 75px;
    left: 0;

    text-align: center;
    @media #{$xx-layout} {
      bottom: 40px;
    }
    @media #{$xl-layout} {
      bottom: 40px;
    }
    @media #{$lg-layout} {
      bottom: 30px;
    }
    @media #{$md-layout} {
      bottom: 30px;
    }
    @media #{$xs-layout} {
      bottom: 30px;
    }
  }
  .position-2 {
    position: absolute;
    bottom: 55px;
    left: 60px;
    @media #{$xx-layout} {
      bottom: 40px;
    }
    @media #{$xl-layout} {
      bottom: 40px;
    }
    @media #{$lg-layout} {
      bottom: 30px;
      left: 40px;
    }
    @media #{$md-layout} {
      bottom: 30px;
      left: 20px;
    }
    @media #{$xs-layout} {
      bottom: 30px;
      left: 20px;
    }
  }
  .position-3 {
    position: absolute;
    top: 51px;
    right: 0;
    left: 0;

    text-align: center;
    @media #{$xx-layout} {
      top: 32px;
    }
    @media #{$xl-layout} {
      top: 32px;
    }
    @media #{$lg-layout} {
      top: 22px;
    }
    @media #{$md-layout} {
      top: 22px;
    }
    @media #{$xs-layout} {
      top: 22px;
    }
  }
  .product-content-4 {
    h4 {
      font-size: 30px;
      line-height: 38px;

      margin: 0 0 12px;
      @media #{$xx-layout} {
        font-size: 25px;
        line-height: 30px;
      }
      @media #{$xl-layout} {
        font-size: 25px;
        line-height: 30px;
      }
      @media #{$lg-layout} {
        font-size: 22px;
        line-height: 27px;
      }
      @media #{$md-layout} {
        font-size: 20px;
        line-height: 23px;
      }
      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 23px;
      }
    }
    .price-4 {
      span {
        font-size: 18px;

        position: relative;

        margin: 0 24px 0 0;

        color: #000000;
        &:before {
          position: absolute;
          top: 12px;
          right: -17px;

          width: 7px;
          height: 2px;

          content: "";

          background-color: #6b6264;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #fa6bff;
        }
      }
      &.price-4-center {
        span {
          margin: 0 12px;
        }
      }
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}

.width-50-percent {
  flex: 0 0 50%;

  max-width: 50%;
}

.width-30-percent {
  flex: 0 0 30%;

  max-width: 30%;
}

/* product home 8*/
.collection-product {
  .collection-img {
    overflow: hidden;
    a {
      img {
        width: 100%;

        transition: all 0.5s ease 0s;
        transform: scale(1);
      }
    }
  }
  .collection-content {
    margin: 23px 0 0;
    span {
      font-weight: 500;
    }
    h4 {
      line-height: 1;

      margin: 4px 0 0;
      a {
        font-size: 16px;
        font-weight: 500;

        color: #000000;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .collection-btn {
      font-size: 13px;
      font-weight: 400;
      line-height: 1;

      position: relative;

      display: inline-block;
      overflow: hidden;

      margin-top: 15px;
      padding: 8px 11px;

      text-transform: uppercase;

      color: #666;
      border: 1px solid #010101;
      border-radius: 50px;
      background-color: transparent;
      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        left: auto;

        width: 0;
        height: 100%;

        content: "";
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:hover {
        color: $white;
        border-color: $theme-color;
        background-color: transparent;
        &:after {
          right: auto;
          left: 0;

          width: 100%;

          background-color: $theme-color;
        }
      }
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}

.product-wrap-5 {
  position: relative;
  .product-img {
    position: relative;

    a {
      img {
        width: 100%;
      }
    }
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      &.purple {
        background-color: $theme-color;
      }
      &.pink {
        background-color: #fa6bff;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .product-action-4 {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;

    width: 100%;
    padding: 10px;

    transition: all 0.5s ease 0s;
    transform: scaleY(0);
    text-align: center;

    opacity: 1;
    background: none repeat scroll 0 0 #a749ff;
    .pro-same-action {
      margin: 0 2px;
      a,
      button {
        font-size: 16px;
        line-height: 36px;

        display: inline-block;

        width: 35px;
        height: 35px;
        margin: 0 3px;

        text-align: center;

        color: #000;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: #fff;
        &:hover,
        &.active {
          color: #fff;
          background-color: #fa6bff;
        }
      }
    }
  }
  .product-content-5 {
    margin-top: 28px;
    h3 {
      font-size: 16px;
      line-height: 17px;

      margin: 0 0 5px;
      a {
        color: #000;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .price-5 {
      span {
        font-size: 15px;

        position: relative;

        margin: 0 8px;

        color: #000000;
        &:before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #fa6bff;
        }
      }
    }
  }
  &:hover .product-action-4 {
    transform: scaleY(1);

    opacity: 1;
  }
}

.product-wrap-6 {
  display: flex;
  .product-img {
    position: relative;

    flex-basis: 170px;

    margin-right: 30px;
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fed700;
      border-radius: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-content {
    flex-basis: calc(100% - 200px);
    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 15px;
      a {
        color: #3d3d3d;
        &:hover {
          color: #fed700;
        }
      }
    }

    .product-price {
      margin-bottom: 10px;
      margin-left: -8px;
      span {
        font-size: 18px;

        position: relative;

        margin: 0 8px;

        color: #000000;
        &:before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #fed700;
        }
      }
    }

    .product-rating {
      margin-bottom: 15px;
      i {
        font-size: 17px;

        margin-right: 3px;
        &.yellow {
          color: #ffa900;
        }
      }
    }

    .product-action {
      display: flex;
      justify-content: flex-end;
      .pro-same-action {
        margin-left: 5px;
        &:first-child {
          margin-left: 0;
        }
        button,
        a {
          font-size: 23px;
          line-height: 55px;

          display: inline-block;

          width: 55px;
          height: 55px;

          text-align: center;

          color: #000;
          border: none;
          background: none;
          background-color: #f6f6f6;

          &:hover,
          &.active {
            color: #fff;
            background-color: #fed700;
          }
        }
      }
    }
  }
}

.product-wrap-7 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;

      color: #fed700;
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      right: 10px;
      bottom: 10px;

      display: flex;
      justify-content: flex-end;

      width: 100%;
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #fff;
        border: none;
        background: none;
        background-color: #555;
        &:hover,
        &.active {
          background-color: #fed700;
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      left: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .pro-wishlist-2 {
      line-height: 35px;

      position: absolute;
      z-index: 9;
      top: 20px;
      right: 20px;

      text-align: center;

      a,
      button {
        font-size: 16px;

        display: inline-block;

        width: 35px;
        height: 35px;

        transition: all 0.3s ease-in-out;

        color: #fff;
        border: none;
        border-radius: 100%;
        background: #555555;
        &:hover,
        &.active {
          background: #fed700;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 16px;
          font-weight: 500;

          color: #444;
          &:hover {
            color: #fed700;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #444;
          &.old {
            text-decoration: line-through;

            color: #444;
            &.red {
              color: #ff3d2a;
            }
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }

  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
}

.product-wrap-8 {
  display: flex;
  .product-img {
    position: relative;

    flex-basis: 170px;

    margin-right: 30px;
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1;

      display: block;

      margin-bottom: 10px;
      padding: 3px 11px;

      color: #fff;
      border-radius: 3px;
      background-color: #ff0000;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-content {
    flex-basis: calc(100% - 200px);
    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 15px;
      a {
        color: #3d3d3d;
        &:hover {
          color: #ff0000;
        }
      }
    }

    .product-price {
      margin-bottom: 10px;
      margin-left: -8px;
      span {
        font-size: 18px;

        position: relative;

        margin: 0 8px;

        color: #000000;
        &:before {
          position: absolute;
          top: 10px;
          right: -13px;

          width: 6px;
          height: 2px;

          content: "";

          background-color: #a5a5a5;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;

          color: #ff0000;
        }
      }
    }

    .product-rating {
      margin-bottom: 15px;
      i {
        font-size: 17px;

        margin-right: 3px;
        &.yellow {
          color: #ffa900;
        }
      }
    }

    .product-action {
      display: flex;
      justify-content: flex-end;
      .pro-same-action {
        margin-left: 5px;
        &:first-child {
          margin-left: 0;
        }
        button,
        a {
          font-size: 23px;
          line-height: 55px;

          display: inline-block;

          width: 55px;
          height: 55px;

          text-align: center;

          color: #000;
          border: none;
          background: none;
          background-color: #f6f6f6;

          &:hover,
          &.active {
            color: #fff;
            background-color: #ff0000;
          }
        }
      }
    }
  }

  &--brown {
    .product-img {
      span {
        background-color: #bca487;
      }
    }
    .product-content {
      h3 {
        a {
          &:hover {
            color: #bca487;
          }
        }
      }

      .product-price {
        span {
          &.old {
            color: #bca487;
          }
        }
      }

      .product-action {
        .pro-same-action {
          button,
          a {
            &:hover,
            &.active {
              background-color: #bca487;
            }
          }
        }
      }
    }
  }
}

.product-wrap-9 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;

      color: #ff0000;
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      right: 10px;
      bottom: 0;

      display: flex;
      justify-content: flex-end;

      width: 100%;
      a,
      button {
        font-size: 16px;
        line-height: 55px;

        display: inline-block;

        width: 55px;
        height: 55px;
        margin: 0 3px;

        transition: all 0.4s ease-in-out;
        transform: scaleX(0);
        text-align: center;

        color: #555;
        border: none;
        background: none;
        background-color: #fff;
        &:hover,
        &.active {
          color: #fff;
          background-color: #ff0000;
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        border-radius: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        font-family: $josefin;
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 16px;
          font-weight: 500;

          color: #444;
          &:hover {
            color: #ff0000;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #444;
          &.old {
            text-decoration: line-through;

            color: #444;
            &.red {
              color: #ff3d2a;
            }
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }
    }
    .pro-wishlist-2 {
      line-height: 35px;

      z-index: 9;

      text-align: center;

      a,
      button {
        font-size: 16px;

        display: inline-block;

        transition: all 0.3s ease-in-out;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: #ff0000;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }

  &:hover .product-action-2 a {
    transform: scaleX(1);
  }
  &:hover .product-action-2 button {
    transform: scaleX(1);
  }
}

.product-wrap-10 {
  position: relative;

  overflow: hidden;
  .product-img {
    position: relative;
    img {
      width: 100%;
    }
    span {
      font-size: 14px;
      font-weight: 500;

      position: absolute;
      top: 20px;
      right: 20px;
    }
    & .default-img {
      transition: all 0.5s ease-in-out;
    }
    & .hover-img {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;

      width: 100%;

      transition: all 0.5s ease-in-out;
      transform: translateX(100%);

      opacity: 0;
    }
    .product-action-2 {
      position: absolute;
      top: 50%;
      left: 20px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      width: auto;

      transform: translateY(-50%);
      a,
      button {
        font-size: 16px;
        line-height: 42px;

        display: inline-block;

        width: 42px;
        height: 42px;
        margin: 3px 0;

        transition: all 0.4s ease-in-out;
        transform: translateX(-200px);
        text-align: center;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: #bca487;
        &:hover,
        &.active {
          background-color: #000;
        }
      }
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      & > span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        position: static;

        display: block;

        margin-bottom: 10px;
        padding: 3px 11px;

        color: #bca487;
        border-radius: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .product-content-2 {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 0;
    .title-price-wrap-2 {
      h3 {
        font-family: $josefin;
        line-height: 1;

        margin: 0 0 6px;
        a {
          font-size: 18px;

          color: #000000;
          &:hover {
            color: #bca487;
          }
        }
      }
      .price-2 {
        span {
          font-size: 15px;

          position: relative;

          margin-right: 17px;

          color: #444;
          &.old {
            text-decoration: line-through;

            color: #444;
          }
          &::before {
            position: absolute;
            top: 10px;
            right: -13px;

            width: 6px;
            height: 2px;

            content: "";

            background-color: #a5a5a5;
          }
          &:last-child:before {
            display: none;
          }
        }
      }
    }
    .pro-wishlist-2 {
      a,
      button {
        font-size: 16px;

        display: inline-block;

        margin: 4px 0 0 10px;

        color: #929292;
        border: none;
        background: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translateX(0);

    opacity: 1;
  }
  &:hover .default-img {
    visibility: hidden;

    transform: translateX(-100%);
  }
  &:hover .product-action-2 a {
    transform: translateX(0);
  }
  &:hover .product-action-2 button {
    transform: translateX(0);
  }

  &--style2 {
    .product-img {
      .product-action-2 {
        button,
        a {
          color: #000;
          background-color: transparent;
          &:hover,
          &.active {
            color: #bca487;
            background-color: transparent;
          }
        }
      }
    }
    &--blue {
      .product-img {
        .product-action-2 {
          button,
          a {
            color: #000;
            background-color: transparent;
            &:hover,
            &.active {
              color: #053399;
              background-color: transparent;
            }
          }
        }

        &-badges {
          & > span {
            color: #053399;
          }
        }
      }

      .product-content-2 {
        .title-price-wrap-2 {
          h3 {
            a {
              &:hover {
                color: #053399;
              }
            }
          }
        }
      }
    }
  }
}

/* product home 9*/

.collection-wrap-2 {
  // margin-top: -175px;
  // @media #{$md-layout} {
  //   margin-top: -97px;
  // }
  // @media #{$xs-layout} {
  //   margin-top: -80px;
  // }
  // @media #{$sm-layout} {
  //   margin-top: -70px;
  // }
}

.collection-product-2 {
  position: relative;
  // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  a {
    // img {
    //   max-width: 100%;

    //   object-fit: cover;

    //   object-position: center;
    //   transition: all 0.5s ease 0s;
    //   transform: scale(1);
    //   @media #{$xxs-layout} {
    //     height: 335px;
    //   }
    // }
  }
  .collection-content-2 {
    position: absolute;
    right: 0;
    bottom: 20px;
    background: rgba(255, 255, 255, 0.7);
    left: 0;
    padding: 5px 0px;
    text-align: center;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;

      margin: 5px 0 0;
    }
  }
  // &:hover a img {
  //   transform: scale(1.1);
  // }
}
.collection-product-2-img {
  // background-image: url(https://dukanyawsv2.imgix.net/meemcdn/resto-140-category/category-frn-khrbayy-main_image-1703049890?fm=webp&h=300&w=300&q=100&fit=center&crop=center);
  height: 350px;
  // width: 349px;
  transition: all 0.5s ease 0s;
  transform: scale(1);
  background-position: center;
  background-size: cover;

  transition: all 0.5s ease 0s;
}
.collection-product-2:hover {
  // &:hover {
  // transition: all 0.5s ease 0s;
  // transform: scale(1.1);
  // }
}

/* product hm9 */
.prduct-hm9-tab {
}
.hm9-section-padding {
  .container-fluid {
    padding-right: 70px;
    padding-left: 70px;
    @media #{$xl-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$lg-layout} {
      padding-right: 30px;
      padding-left: 30px;
    }
    @media #{$md-layout} {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media #{$xs-layout} {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media #{$sm-layout} {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .custom-row-4 {
    // margin-right: -10px;
    // margin-left: -10px;
    @media #{$xs-layout} {
      margin-right: 0px;
      margin-left: 0px;
    }
    @media #{$sm-layout} {
      margin-right: -10px;
      margin-left: -10px;
    }
  }
  div[class^="custom2-"] {
    @media #{$xs-layout} {
    }
    @media #{$sm-layout} {
    }
  }
}
.custom-row-4 {
  display: flex;
  flex-wrap: wrap;
  .custom2-col-5:last-child {
    border-left: 1px solid #f1f1f1;
  }
}
.product-single-wrap {
  background-color: #fff;
  margin: 0 3px 30px;
  border-radius: 10px;
  padding: 0px;
}
.custom2-col-5 {
  position: relative;
  flex: 1 0 19%;
  width: 100%;
  max-width: 20%;
  min-height: 1px;

  @media #{$xl-layout} {
    flex: 1 0 19%;

    max-width: 25%;
  }
  @media #{$lg-layout} {
    flex: 1 0 19%;

    max-width: 33.333%;
  }
  @media #{$md-layout} {
    max-width: 50%;
  }
  @media #{$xs-layout} {
    flex: 1 0 48%;
    max-width: 50%;

    // margin-right: 7px;
    // margin-bottom: 10px;
  }
  @media #{$sm-layout} {
    flex: 1 0 48%;
    max-width: 50%;
  }
}
.custom2-col-12 {
  position: relative;
  flex: 0 0 19%;
  width: 100%;
  max-width: 20%;
  min-height: 1px;

  @media #{$xl-layout} {
    flex: 0 0 19%;

    max-width: 25%;
  }
  @media #{$lg-layout} {
    flex: 0 0 19%;

    max-width: 33.333%;
  }
  @media #{$md-layout} {
    max-width: 50%;
  }
  @media #{$xs-layout} {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media #{$sm-layout} {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*------- home 14 ----------*/

.product-top-bar {
  display: flex;
  justify-content: space-between;
  @media #{$xs-layout} {
    display: block;
    justify-content: inherit;

    text-align: center;
  }
}

.product-tab-list-3 {
  position: relative;
  z-index: 99;

  margin: 4px 0 0;
  @media #{$xs-layout} {
    justify-content: center;

    margin: 15px 0 0;
  }
  a {
    margin-left: 24px;
    &.nav-link {
      padding: 0;

      background-color: transparent !important;
    }
    &.active {
      h4 {
        color: #242424;
      }
    }
    &:first-child {
      margin-left: 45px;
      @media #{$xs-layout} {
        margin-left: 20px;
      }
    }
    h4 {
      font-size: 14px;
      line-height: 1;

      margin: 0;

      color: #878787;
    }
  }
}

.product-tab-list-5 {
  justify-content: center;

  width: 100%;
  a {
    font-size: 16px;
    line-height: 1;

    display: inline-block;

    margin: 0 20px;
    padding: 0 0 7px;

    color: #000000;
    border-bottom: 2px solid transparent;
    &.nav-link {
      padding: 0;

      border-radius: 0;
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      font-size: 16px;

      margin: 0 6px;
    }

    &.active {
      border-bottom: 2px solid #59a3e1;
      h4 {
        color: #59a3e1;
      }
    }
  }
  @media #{$xs-layout} {
    &.mb-60 {
      margin-bottom: 25px;
    }
  }
}

.product-tab-list-6 {
  @media #{$xs-layout} {
    margin: 0 0 10px;
  }
  @media #{$sm-layout} {
    margin: 0 0 0;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #555;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      h4 {
        color: #bca487;
      }
    }
  }
}

.product-tab-list-7 {
  border-bottom: 2px solid #d1d1d1;
  @media #{$xs-layout} {
    margin: 0 0 30px;
  }

  .nav-item {
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  a {
    display: inline-block;

    margin-right: 23px;
    &.nav-link {
      border-radius: 0;
      background-color: transparent !important;
    }
    @media #{$xs-layout} {
      margin: 0 23px 10px 0;
    }
    @media #{$sm-layout} {
      margin: 0 23px 0 0;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-family: $josefin;
      font-size: 24px;
      font-weight: 700;

      margin: 0;
      padding-bottom: 5px;

      transition: all 0.3s ease 0s;

      color: #010101;
      &:hover {
        color: #000000;
      }
    }
    &.active {
      position: relative;
      &:after {
        position: absolute;
        bottom: -2px;
        left: 0;

        width: 100%;
        height: 2px;

        content: "";

        background-color: #053399;
      }

      h4 {
        color: #053399;
      }
    }
  }
}

.product-slider-active {
  .owl-nav {
    position: absolute;
    top: -85px;
    right: 0;
    @media #{$xs-layout} {
      top: -45px;
      right: 0;
      left: 0;

      text-align: center;
    }
    div {
      font-size: 18px;
      line-height: 31px;

      display: inline-block;

      width: 35px;
      height: 35px;

      transition: all 0.3s ease 0s;
      text-align: center;

      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      background-color: #f9f9f9;
      @media #{$xs-layout} {
        font-size: 15px;
        line-height: 23px;

        width: 25px;
        height: 25px;
      }
      &:hover {
        color: #fff;
        border: 1px solid #ff3d2a;
        background-color: #ff3d2a;
      }
      &.owl-next {
        margin-left: 3px;
      }
    }
  }
}

.pro-view-more {
  margin-top: 30px;
  @media #{$xs-layout} {
    margin-top: 0;
  }
  a {
    font-size: 16px;
    line-height: 1;

    display: inline-block;

    padding: 20px 35px 19px;

    text-transform: uppercase;

    color: #828282;
    border: 1px solid #d2d2d2;
    i {
      margin-right: 10px;
    }
    &:hover {
      color: #fff;
      border: 1px solid #df262b;
      background-color: #df262b;
    }
  }
}

.bg-png-1 {
  position: absolute;
  top: 0;
  left: 78px;
  @media #{$xs-layout} {
    display: none;
  }
}
.funfact-area {
  position: relative;
}
.bg-png-2 {
  position: absolute;
  top: 0;
  right: 550px;
  @media #{$xx-layout} {
    right: 300px;
  }
  @media #{$xl-layout} {
    right: 250px;
  }
  @media #{$lg-layout} {
    right: 150px;
  }
  @media #{$md-layout} {
    right: 120px;
  }
  @media #{$xs-layout} {
    display: none;
  }
}

.product-quickview-modal-wrapper {
  .modal-dialog {
    // margin: 5% auto;
    padding: 0;
  }
}

.category-grid-area {
  margin-top: -100px;

  @media #{$lg-layout} {
    margin-top: -50px;
  }

  @media #{$md-layout} {
    margin-top: 100px;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
}

.category-grid {
  position: relative;
  z-index: 9;
  &__image {
    a {
      img {
        width: 100%;
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 20px;

    width: 100%;
    a {
      font-size: 18px;
      font-weight: 400;

      display: block;

      width: 80%;
      margin: 0 auto;
      padding: 25px 15px;

      text-align: center;

      color: #000;
      background-color: #fff;
      &:hover {
        background-color: #bca487;
      }
    }
  }
}
