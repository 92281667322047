@font-face {
  font-family: "expo-arabic-light";
  src: url("../fonts/taiftecFonts/Expo-Arabic-Light.ttf");
    font-display: swap;
}

@font-face {
  font-family: "expo-arabic-medium";
  src: url("../fonts/taiftecFonts/Expo-Arabic-Medium.ttf");
    font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/taiftecFonts/NunitoSans_10pt-Regular.ttf");
    font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/taiftecFonts/NunitoSans_10pt-Bold.ttf");
    font-display: swap;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/taiftecFonts/NunitoSans_10pt-Light.ttf");
    font-display: swap;
}
:root {
  /* --font-family-global: "Times New Roman"; */
  --font-ar-light: "expo-arabic-light";
  --font-ar-medium: "expo-arabic-medium";
  --font-en-regular: "Roboto-Regular";
  --font-en-light: "Roboto-Light";
  --font-en-medium: "Roboto-Medium";
  --single-banner-color-left: #c7d2e3;
  --single-banner-color-right: #dfe7f1;
  --button-color: blue;
  --mouse-hover-color: #c7d2e3;
  --mouse-selection-color: #c7d2e3;
  --categorySliderBtnColorActive: "#9fc5ea";
  --categorySliderBtnColorInActive: "#d1dfec";
  --product-tex-color: #000;
  --slider-text-color: #1a0c0c;
  --slider-background-color: #dfe9f1;
  --product-text-color: #000;
  --pre-loader-color: #000;
  --theme-color-light: rgb(217, 217, 226);
  --theme-border: blue;
  --toastify-icon-color-success: white;
  --toastify-color-success: #21c55e;
  --toastify-color-error: #ef5350;
  --toastify-font-family: var(--font-en-regular);
  --toastify-toast-min-height:54;
  --old-price-color: var(--button-color);
  --old-price-font-size:12px
}

// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$greatvibes: "Great Vibes", cursive;
$lobster: "Lobster", cursive;
$josefin: "Josefin Sans", sans-serif;

$font-ar-ligth: "expo-arabic-light";
$font-ar-medium: "expo-arabic-medium";
$font-en-regular: "Roboto-Regular";
$font-en-light: "Roboto-Light";
$font-en-medium: "Roboto-Medium";

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;
$cart-title: #757575;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
// $theme-color: var(--hero-slider-color);
// $theme-color: #184494;
// #a749ff;
$theme-button-color: var(--button-color);
$theme-color: var(--button-color);
// button background color
$button-background-color: #184494;
.border-color {
  border: 1px solid $theme-color;
}
.button-bg {
  text-transform: uppercase;
  background-color: var(--button-color);
}
// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xxxl-layout: "only screen and (min-width: 1441px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
$mw-1000: "only screen and (min-width: 320px) and (max-width: 1000px)";
$lg-only: "only screen and (max-width: 1000px)";
$sm-only: "only screen and (min-width: 1000px)";
$minw900: "only screen and (min-width: 920px)";
