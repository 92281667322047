/*------- 14. Brand logo style  -------*/
.shop-by-brand-title {
  // color: #939393;
  color: #4b4b4b;
  font-weight: 500;
  font-size: 18pt;
  margin-bottom: 20px;
}
.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .single-brand-logo,
  .single-brand-logo2 {
    height: 100%;
    width: 95%;
    border-radius: 8px;
    border: 1px solid #dfdfdf80;

    text-align: center;
    img {
      // object-fit: cover;
      // height: 100%;
      // width: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;

      // filter: grayscale(100%);
      transition: all 0.5s ease 0s;
    }
    &:hover img {
      filter: grayscale(0%);
    }

    #single-brand-slide {
      filter: grayscale(100%);
      transition: all 0.5s ease 0s;
      &:hover {
        filter: grayscale(0%);
      }
    }
  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}
.bg-image-brands-slider {
  // background-image: url(https://dukanyawsv2.imgix.net/meemcdn/resto-140-category/category-frn-khrbayy-main_image-1703049890?fm=webp&h=300&w=300&q=100&fit=center&crop=center);
  height: 116px;
  max-width: 132px;
  transition: all 0.5s ease 0s;
  //   transform: scale(1);
  background-position: 50%;
  background-size: cover;
  // .bg-image-brands-slider{
  margin: auto !important;
  // }
  transition: all 0.5s ease 0s;
}
