.enter-mobile {
  font-size: 18px;
}
.otp-title-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.my-div-styles {
  background: yellow;
  /* padding: 10px; */
  padding-left: 14px;
  padding-right: 11px;
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 14px;
  text-align: center;
}
.button-1 {
  background-color: bisque;
}
.button-2 {
  background-color: orange;
}
.cross-button {
  margin-top: 10px;
  padding: 10px 20px;
  display: flex;
  font-size: 20px;
}
.modal-close-wrapper {
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.otp-method-txt {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}

.line-separator {
  height: 0.5px;
  width: 100%;
  background-color: grey;
  margin-top: 40px;
}