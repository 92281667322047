.trackOrderStatusWrapper{
    /* animation:; */
    transition: .5s;
    padding: 0 30px 0 30px;
  }
    .OR_title {
      color: #a0aebf;
      margin-bottom: .25rem;
    }
  .openReceiptButton{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  .align_rig {
    text-align: right;
  }
  .title_acc_cell {
    font-size: 14px;
    /* font-weight: 700; */
   
    /* padding-bottom: 10px; */
    padding-bottom: 1px;
    /* margin-bottom: 10px; */
  
    /* color: #000; */
    color: #000000;
  }
  .track_order_bussiness_details {
    padding: 10px 30px 15px;
      display: flex;
    //   position: absolute;
      width: 100%;
      bottom: 8rem;
      z-index: 100;
  }
  .loader-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background: antiquewhite; */
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .light_grey_text {
    color: #828282;
    font-weight: 300 !important;
    padding-right: 5px;
  }
  .pad_10_up_down {
    /* padding: 10px 0px; */
    padding-bottom: 14px;
    padding-top: 3px;
  }
  .cart_content_col_21_5 {
    width: 2px;
    height: 4rem;
    background-color: $theme-color;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .black_color {
    color: black;
  } 
  .border_right {
    border-right: 2px solid $theme-color;
  }