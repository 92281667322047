.announcement-bar {
    width: 100%;
    background-color: var(--button-color);
    /* Blue background */
    // color: white;
    padding: 12px 40px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

/* Continuous scrolling animation */
.marquee {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.marquee p {
    color: white;
    display: inline-block;
    white-space: nowrap;
    animation: marquee-scroll 10s linear infinite;
}

@keyframes marquee-scroll {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

/* Close button */
.close-btn {
    position: absolute;
    right: 15px;
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.close-btn:hover {
    color: #ffcccc;
}

/* Responsive styles */
@media (max-width: 768px) {
    .announcement-bar {
        font-size: 12px;
        padding: 10px 30px;
    }

    .close-btn {
        font-size: 16px;
    }

    @keyframes marquee-scroll {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(-100%);
        }
    }
}