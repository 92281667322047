.react-tel-input .form-control {
  border: 1px solid var(--button-color) !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid var(--button-color) !important;
}
.react-tel-input .form-control {
  height: 46px !important;
  width: 100% !important;
}
.react-tel-input {
  width: auto !important;
}

.react-tel-input .country-list {
  top: -12px !important;
}
